$white: #ffffff;

$dark-gray: #20172f;
$light-gray: #d6d4eb;
$lighter-gray: #f1f2fd;

$light-blue: #3299bb;

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  background: $white;
  color: $dark-gray;
  font-family: helvetica, sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

a {
  color: $light-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  line-height: 1.5rem;
}

.header,
.footer {
  background: $dark-gray;
  box-shadow: 0 0 5px #111;
  color: $light-gray;
}

.header {
  font-size: 2rem;
  width: 100%;
  top: 0;

  a {
    color: $light-gray;

    &:hover {
      color: $light-blue;
      text-decoration: none;
    }
  }
}

.main-header,
.menu-icon {
  padding: 1rem;
  margin: 0;
}

.main-header {
  font-size: 1.8rem;
}

.footer {
  border-width: 1px 0 0 0;
  padding: 1rem;
}

.feedback {
  margin-top: 10px;
}

.header-nav {
  font-size: 1.2rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  height: 2rem;
  line-height: 2rem;
  padding: 0 2rem;
}

.menu-icon:hover {
  color: $dark-gray;
  text-decoration: none;
  cusor: pointer;
}

.section-header {
  background-color: $lighter-gray;
}

.content {
  min-height: 100%;
  position: relative;
}

.last-updated {
  font-size: 1rem;
  font-style: italic;
  margin-left: 0.5rem;
  line-height: 2.5rem;
  vertical-align: middle;
}

.footnote {
  font-size: 0.75rem;
}

.loading {
  background: url("images/loader.gif");
  background-position: left center;
  background-repeat: no-repeat;
  margin-top: 20px;
  padding-left: 20px;
}

.wgi {
  background: url("images/wgi_logo.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 32px;
  height: 16px;
  margin-right: 0.5rem;
}

.diff {
  color: red;
  font-size: 0.75rem;
}

/* Scroll Threshold */
.scrolled {
  .header {
    position: fixed;
    z-index: 1;
  }

  .main-header {
    display: none;
  }

  .content {
    padding-top: 100px;
  }
}

/* Scroll to top */
.scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: -100px;
  padding: 10px;
  border: 0;
  border-radius: 5px 0 0 5px;
  background: $dark-gray;
  box-shadow: 0 0 5px $dark-gray;
  color: $light-gray;
  cursor: pointer;
  transition: right 0.218s ease-in-out;
  z-index: 1500;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $light-blue;
  }

  &.visible {
    right: 0;
  }
}

.scores {
  margin: 0;
  padding: 1rem 0;
  position: relative;

  &:before {
    content: " ";
    background: $light-blue;
    width: 0.2rem;
    border-radius: 0.2rem;
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 1.9rem;
    z-index: -1;
  }
}

.score {
  list-style: none;
  margin: -1px 0 0;
  padding: 0;
  display: flex;
  align-items: top;
}

.score-rank,
.score-score,
.score-unit,
.score-date,
.score-info-icon {
  margin-right: 0.5rem;
}

.score-rank,
.score-info {
  display: inline-block;
}

.score-info {
  max-width: 85%;
  vertical-align: top;
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.score-rank {
  background: $dark-gray;
  border-radius: 50%;
  border: 2px solid $light-gray;
  color: $light-gray;
  width: 2rem;
  height: 2rem;
  line-height: 1.9rem;
  text-align: center;
}

.score-score,
.score-unit {
  font-weight: bold;
}

.score-location {
  font-weight: lighter;
}

.score-date {
  font-style: italic;
}

.score-info-icon {
  display: flex;
  align-items: center;
}

@media (max-width: 770px) {
  .main-header,
  .menu-icon {
    padding: 0 1rem;
  }

  .main-header {
    font-size: 1.2rem;
    line-height: 3rem;
  }

  .header-nav {
    font-size: 0.8rem;
  }

  .header-nav {
    box-shadow: inset 0 0 150px #111;
    overflow: hidden;
    padding: 0;
    height: 0;
    width: 100%;
    -webkit-transition: all 250ms ease-in-out;
  }

  .expanded {
    height: 20rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .content,
  .scrolled .content {
    padding-top: 0;
  }

  .scrolled .header {
    position: relative;
  }

  .scrolled .main-header {
    display: block;
  }
}
.btn,
.btn-secondary,
.btn-light {
  &.focus,
  &:focus {
    box-shadow: none;
  }
}
